import {LookupItem} from '@shared/erp-api'

/**
 * Generate Cell Id
 *
 * Generates a unique id for an antd table cell
 *
 * @function
 *
 * @param {LookupItem} item - cell item
 * @param {string | string} key - a unique key that provides eventually a unique id if there's more than 1 cell with the same item on the page
 *
 * @returns {string}
 */
export function generateCellId(item: LookupItem, key?: string): string {
  return `cell-id-${item._id}-${item.__type}${key ? `-${key}` : ''}`
}
