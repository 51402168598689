import {Card, Space} from 'antd'
import React, {useMemo} from 'react'
import CartSubCategory from './cart-subcategory'
import {CartCategory, CartDomain} from './cart.t'

interface CartCategoryProps {
  cart: CartDomain
  category: CartCategory
}

const CartCategory: React.FC<CartCategoryProps> = ({cart, category}: CartCategoryProps) => {
  const defaultBack = '#9B9B9B'
  const defautlFont = '#3E3E3F'

  const back = useMemo(() => (category.color ? category.color : defaultBack), [category])
  const font = useMemo(() => (category.fontColor ? category.fontColor : defautlFont), [category])

  return (
    <Card
      title={category.name}
      headStyle={{color: font, fontSize: '1.1rem', background: back, paddingBottom: '5px'}}
      bodyStyle={{padding: '0px'}}>
      <Space direction='vertical' className='w-100'>
        {category.subCategories.map((sub, index) => (
          <CartSubCategory
            key={index}
            cart={cart}
            category={category}
            subCategory={sub}
            backColor={back}
            fontColor={font}
          />
        ))}
      </Space>
    </Card>
  )
}

export default CartCategory
