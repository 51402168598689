import {House} from '@shared/interfaces'
import {Product} from '@shared/interfaces'
import React, {useEffect, useMemo} from 'react'
import {useCataLogContext} from '@store/catalog/context'
import Dropdown from './dropdown'
import {generateOptions, getDefaultOptions} from './utils'
import OptionsSelect from './options-select'
import Searchbar from './search-bar'
import _ from 'lodash'
import ToggleSwitch from './toggle-switch'
import {useApp} from '@store/app'
import {ArrowLeftOutlined, EyeFilled, EyeInvisibleFilled} from '@ant-design/icons'

const Sidebar: React.FC = () => {
  const {
    data,
    products,
    filters,
    options,
    setFilters,
    unsetFilter,
    isLoading,
    setSidebarHidden,
    setArticle,
    extended,
    setExtended,
    onClose,
    optionsSelected,
    setOptionsSelected,
    cart,
    hasProject,
    sidebarHidden
  } = useCataLogContext()

  const {t} = useApp()

  const [productsOptions, housesOptions] = useMemo(
    () => [products, data?.houses].map((it) => generateOptions<Product | House>(it || [])),
    [products, data?.houses]
  )

  useEffect(() => {
    const optionsFilter = Object.keys(optionsSelected || {}).map((option) => ({
      option,
      value: (optionsSelected || {})[option]
    }))
    if (!_.isEmpty(optionsFilter)) {
      setFilters?.({...filters, options: optionsFilter})
    } else {
      unsetFilter?.('options')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsSelected])

  const onProductChange = (productId?: string) => {
    const defaultOptions = getDefaultOptions(cart, productId ?? filters?.product)
    setFilters?.({
      ...filters,
      product: productId,
      options: _.chain(defaultOptions)
        .omitBy(_.isNil)
        .toPairs()
        .map(([option, value]) => ({option, value: `${value}`}))
        .value()
    })
    setOptionsSelected?.(defaultOptions)
    setArticle?.(undefined)
  }

  const sidebarComponent = sidebarHidden ? (
    <div className='catalog-sidebar-hide-container'>
      <p
        className='cursor-pointer catalog-sidebar-show-icon'
        onClick={() => {
          setSidebarHidden?.(false)
        }}>
        <EyeFilled />
      </p>
    </div>
  ) : (
    <div className='gotham-font catalog-sidebar-container'>
      {hasProject && (
        <span
          className='gotham-font cursor-pointer catalog-sidebar-back'
          onClick={() => onClose?.()}>
          <ArrowLeftOutlined style={{marginRight: '10px'}} />
          {t('catalog.sidebar.back')}
        </span>
      )}
      {!hasProject && (
        <Dropdown
          label='catalog.sidebar.houseSelect'
          options={housesOptions}
          value={filters?.house}
          onChange={(value) => {
            setFilters && setFilters({...filters, house: value})
            setArticle && setArticle(undefined)
          }}
          t={t}
        />
      )}
      <Searchbar
        t={t}
        value={filters?.articleName}
        onChange={(value) => {
          setFilters && setFilters({...filters, articleName: value})
        }}
        isLoading={isLoading}
      />
      <Dropdown
        label='catalog.sidebar.productSelect'
        options={productsOptions}
        value={filters?.product}
        onChange={onProductChange}
        t={t}
      />
      <ToggleSwitch
        label='Offre étendue'
        checked={!!extended}
        onChange={(value: boolean) => setExtended?.(!!value)}
      />
      {options && (
        <OptionsSelect
          options={options}
          optionsSelected={optionsSelected || {}}
          setOptionsSelected={setOptionsSelected}
          t={t}
        />
      )}
      <span
        className='catalog-sidebar-hide-menu cursor-pointer'
        onClick={() => {
          setSidebarHidden?.(true)
        }}>
        <span className='catalog-sidebar-hide-menu-label'>{t('catalog.sidebar.hideMenu')}</span>
        <EyeInvisibleFilled className='catalog-sidebar-hide-icon' />
      </span>
    </div>
  )

  return (
    <div>
      <div className='catalog-sidebar-menu'>{sidebarComponent}</div>
    </div>
  )
}

export default Sidebar
