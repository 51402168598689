import PANELS from '@components/panels/index'
import {FormikProps} from 'formik'
import {FormObject} from '@components/forms/form.t'
import {Drawer, Modal} from 'antd'
import _ from 'lodash'
import React, {useRef, useState} from 'react'
import {Panels, PanelsKeys, PanelState, PanelStyle, PanelsWarpperProps} from './panels.t'

const renderPanel = (key: PanelsKeys, props: object) => {
  if (!PANELS[key]) return

  // @ts-expect-error Panel factory type error
  return React.createElement(PANELS[key], {key, panelKey: key, ...props})
}

export const usePanels: () => Panels = () => {
  const [panels, setPanels] = useState<PanelState[]>([])

  const panelsRef = useRef<PanelState[]>()
  panelsRef.current = panels

  const formRef = useRef<FormikProps<FormObject> | undefined>()

  const isOpen = (key: PanelsKeys) => !!_.filter(panelsRef.current, {key}).length
  const show = (
    key: PanelsKeys,
    props: object = {},
    style: PanelStyle = PanelStyle.DRAWER,
    panelProps: object = {}
  ) =>
    setPanels([
      ...panels,
      {key, props, style, panelProps, render: renderPanel(key, {...props, formRef}), formRef}
    ])

  const close = (key: PanelsKeys) => {
    const rpanels = panelsRef.current?.reverse() || []
    const index = rpanels.map((p) => p.key).indexOf(key)
    if (index >= 0) {
      rpanels.splice(index, 1)
      setPanels([...rpanels.reverse()])
    }
  }

  const toggle = (
    key: PanelsKeys,
    props: object,
    style: PanelStyle = PanelStyle.DRAWER,
    panelProps: object = {}
  ) => (isOpen(key) ? close(key) : show(key, props, style, panelProps))

  return {panels, isOpen, show, close, toggle}
}

const PanelsWrapper = ({control, t}: PanelsWarpperProps) => {
  return (
    <React.Fragment>
      {control.panels.map(({key, render, style, panelProps}) => {
        return (
          <React.Fragment key={key}>
            {style === PanelStyle.MODAL && (
              <Modal
                keyboard={false}
                open={control.isOpen(key)}
                onCancel={() => control.close(key)}
                afterClose={() => {
                  control.close(key)
                }}
                footer={null}
                centered
                bodyStyle={{paddingTop: 20}}
                {...panelProps}>
                {render}
              </Modal>
            )}
            {style === PanelStyle.DRAWER && (
              <Drawer
                keyboard={false}
                open={control.isOpen(key)}
                size='large'
                closable={false}
                onClose={() => control.close(key)}
                {...panelProps}>
                {render}
              </Drawer>
            )}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default PanelsWrapper
