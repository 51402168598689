import {ReadOutlined} from '@ant-design/icons'
import {Row, Col, Space, Button, Typography} from 'antd'
import React, {useCallback, useMemo} from 'react'
import CartArticleActions from './cart-article-actions'
import {CartDomain, CartCategory, CartItemDomain, CartItemStatus} from './cart.t'
import {useApp} from '@store/app'
import {PanelsKeys} from '@libs/panels/panels.t'

const {Text} = Typography
interface CartArticleProps {
  cart: CartDomain
  category: CartCategory
  cartItem: CartItemDomain
}

const CartArticle: React.FC<CartArticleProps> = ({cart, cartItem}: CartArticleProps) => {
  const {Panels} = useApp()

  const openInCatalog = useCallback(() => {
    Panels.show(PanelsKeys.CATALOG, {
      product: cartItem?.product._id,
      cart,
      cartItem,
      Panels
    })
  }, [cartItem, cart, Panels])

  const hasCatalog = useMemo(() => {
    return (
      cartItem.product.isCatalog && !cart.locked && cart.status === 'estimate' && !cart.isAbandoned
    )
  }, [cartItem.product.isCatalog, cart.locked, cart.status, cart.isAbandoned])

  const itemData = useMemo(() => {
    if (cartItem.status === CartItemStatus.APPROVED)
      return {
        name: cartItem.name,
        price: cartItem.price,
        description: cartItem.internalDescription
      }

    return {
      name: cartItem.product.name,
      description: cartItem.product.description
    }
  }, [cartItem])

  return (
    <Col className='px-4 py-3'>
      <Space direction='vertical' className='w-100' size='small'>
        <Row align='middle' justify='space-between' className='fw-bold' wrap={false}>
          <Col>
            <Row align='middle' wrap={false}>
              <Space>
                <Col>
                  <Text strong>{itemData.name}</Text>
                </Col>
                {hasCatalog && (
                  <Col>
                    <Button
                      type='text'
                      shape='circle'
                      icon={<ReadOutlined />}
                      onClick={openInCatalog}
                    />
                  </Col>
                )}
              </Space>
            </Row>
          </Col>
          <Col>
            <Row align='middle' gutter={16}>
              {itemData.price && (
                <Col>{itemData.price.ttc === 0 ? 'OFFERT' : itemData.price.string.ttc}</Col>
              )}
              <Col>
                <CartArticleActions cart={cart} cartItem={cartItem} />
              </Col>
            </Row>
          </Col>
        </Row>
        {itemData.description && (
          <Row>
            <Col>
              <Text>{itemData.description}</Text>
            </Col>
          </Row>
        )}
      </Space>
    </Col>
  )
}

export default CartArticle
