import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteFilled,
  EditFilled,
  MoreOutlined,
  QuestionCircleTwoTone,
  SwapOutlined
} from '@ant-design/icons'
import {PanelsKeys} from '@libs/panels/panels.t'
import {fetchGetByIdQuery, invalidateDomainQueries, useRemoveMutation} from '@queries'
import {PRODUCT_FINDER_ACTION_ID, useInmemoriServices} from '@services'
import {AppContext, withApp} from '@store/app'
import {useQueryClient} from '@tanstack/react-query'
import {Button, Dropdown, MenuProps, Row, Space, theme} from 'antd'
import React, {useMemo} from 'react'
import {CartDomain, CartItemDomain, CartItemStatus} from './cart.t'
import {useDomainByKey} from '@hooks/use-domain'
import {ErpDomain} from '@shared/interfaces'

const {useToken} = theme

interface CartArticleActionsProps extends AppContext {
  cart: CartDomain
  cartItem: CartItemDomain
}

const CartArticleActions: React.FC<CartArticleActionsProps> = ({
  cart,
  cartItem,
  toast,
  t,
  Panels,
  ability,
  CRM
}: CartArticleActionsProps) => {
  const {token} = useToken()

  const {status, searchContext} = cartItem
  const searchProduct = searchContext?.product

  const {erpApiService} = useInmemoriServices()
  const queryClient = useQueryClient()
  // const SM = window.SM

  // const action = CRM.getDomain('cart').actions.find((a) => a.key === 'productFinder')

  const statusIcon = useMemo(() => {
    if (!status) return undefined

    switch (status) {
      case CartItemStatus.APPROVED:
        return <CheckCircleTwoTone twoToneColor={token.colorSuccess} />

      case CartItemStatus.PENDING:
        return <QuestionCircleTwoTone twoToneColor={token.colorWarning} />

      case CartItemStatus.MISSING:
        return <CloseCircleTwoTone twoToneColor={token.colorError} />
    }
  }, [status, token.colorError, token.colorSuccess, token.colorWarning])

  const onSave = async (id?: string) => {
    try {
      await invalidateDomainQueries(queryClient, 'carts', id)
    } catch (e) {
      console.error('edit error', e)
    }
  }

  const edit = async () => {
    try {
      const cartData = await fetchGetByIdQuery(
        'cartItems',
        cartItem._id,
        erpApiService,
        queryClient,
        {api: {hydrate: true}}
      )

      Panels.toggle(PanelsKeys.FORM_EDIT, {
        domain: 'cartItem',
        data: cartData,
        onSave
      })
    } catch (err) {
      console.error('Error while fetching cartItem', err)
    }
  }

  const cartItemDomain = useDomainByKey('cartItem')
  const removeMutation = useRemoveMutation()

  const remove = async () => {
    cartItemDomain &&
      removeMutation
        .mutateAsync(
          {domain: cartItemDomain, id: cartItem._id},
          {
            onSuccess() {
              onSave(cart._id)
              toast.success('cart-widget.actions.success')
            }
          }
        )
        .catch((err) => {
          const errorMessage = err.response?.data?.data?.err || 'cart-widget.actions.error'
          toast.error(errorMessage)
        })
  }

  const swap = async () => {
    Panels.toggle(PanelsKeys.PRODUCT_FINDER, {
      action: PRODUCT_FINDER_ACTION_ID,
      cart,
      cartItem,
      searchContext
    })
  }

  const productFinderAction = useMemo(() => {
    return (CRM?.getDomain('cart') as ErpDomain).actions.find((a) => a.key === 'productFinder')
  }, [CRM])

  const resolve = async () => {
    if (
      status === CartItemStatus.APPROVED ||
      !productFinderAction ||
      ability?.cannot(`action.${productFinderAction._id}`, 'cart')
    )
      return

    Panels.toggle(PanelsKeys.PRODUCT_FINDER, {
      action: PRODUCT_FINDER_ACTION_ID,
      cart,
      cartItem,
      searchContext
    })
  }

  const onMenuClick = (e: {key: string}) => {
    switch (e.key) {
      case 'edit':
        edit()
        break
      case 'delete':
        remove()
        break
      case 'swap':
        swap()
        break
    }
  }

  const actionMenu = useMemo(() => {
    const items: MenuProps['items'] = []

    if (status === CartItemStatus.APPROVED) {
      if (ability?.can('crud.update', cartItem)) {
        items.push({
          key: 'edit',
          label: 'Modifier',
          icon: <EditFilled />
        })
      }

      if (
        searchProduct &&
        productFinderAction &&
        (ability?.can(`action.${productFinderAction._id}`, 'cart') ||
          ability?.can('action.all', 'cart'))
      ) {
        items.push({
          key: 'swap',
          label: 'Changer',
          icon: <SwapOutlined />
        })
      }
    }

    if (ability?.can('crud.delete', cartItem)) {
      items.push({
        key: 'delete',
        label: 'Supprimer',
        icon: <DeleteFilled />,
        danger: true
      })
    }

    return items
  }, [ability, cartItem, productFinderAction, searchProduct, status])

  return (
    <Row align='middle' wrap={false}>
      <Space>
        {!!statusIcon && (
          <Button type='text' shape='circle' icon={statusIcon} onClick={() => resolve()} />
        )}
        {!(cart.locked || cart.isAbandoned) && !!actionMenu.length && (
          <Dropdown menu={{items: actionMenu, onClick: onMenuClick}} placement='bottomLeft' arrow>
            <Button type='text' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )}
      </Space>
    </Row>
  )
}

export default withApp(CartArticleActions)
