import {LookupItem} from '@shared/erp-api'
import {Col, Row, Typography, theme} from 'antd'
import LookupTableCellStatus from './lookup-table-cell-status'
import {generateCellId} from '../custom-table/utils'
import {CSSProperties, useMemo} from 'react'

const {Text} = Typography

const {useToken} = theme
export interface LookupTableCellProps {
  lookupItem: LookupItem
  compact: boolean
  id: string | undefined
}

const LookupTableCell = (props: LookupTableCellProps) => {
  const {lookupItem, compact} = props

  const [title, state, ...rest] = lookupItem.displayValues

  const {token} = useToken()

  const contentColSpan = useMemo<number>(() => (compact ? 24 : 22), [compact])

  const detailsCssStyle = useMemo<CSSProperties>(
    () => ({
      color: token.colorTextSecondary
    }),
    [token]
  )

  return (
    <label htmlFor={generateCellId(lookupItem, props.id)} style={{width: '100%'}}>
      <Row>
        {!compact && state && state.length && (
          <Col span={2}>
            <LookupTableCellStatus stringValue={state} />
          </Col>
        )}
        <Col span={contentColSpan}>
          <Text strong>{title}</Text>
          <Col style={detailsCssStyle}>
            {rest.map((value, index) => (
              <Text key={index} type='secondary' className='fs-small pre-line d-block'>
                {value}
              </Text>
            ))}
          </Col>
        </Col>
      </Row>
    </label>
  )
}

export default LookupTableCell
