import {useDomainByKey} from '@hooks/use-domain'
import {useInmemoriServices} from '@services'
import {Cart} from '@shared/interfaces'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {invalidateDomainQueries} from './domains'
import {useCataLogContext} from '@store/catalog/context'

export function useAddToCartMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService
  const cartDomain = useDomainByKey('cart')

  const {cartItem: selectedCartItem} = useCataLogContext()

  return useMutation({
    mutationFn: ({
      allArticleIds,
      articleId,
      productId,
      cart
    }: {
      allArticleIds: string[]
      articleId: string
      productId: string
      cart: Cart
    }) => {
      const cartItem =
        selectedCartItem ||
        cart?.cartItems.find(
          (it) => it?.searchContext?.product === productId || allArticleIds.includes(it.article)
        )
      return cartItem
        ? erpApi.replaceCartItemArticle(articleId, cartItem, true) // Added from catalog
        : erpApi.addToCart([articleId], cart._id)
    },
    onSuccess: (response, {cart}) =>
      cartDomain && invalidateDomainQueries(queryClient, 'carts', cart._id)
  })
}
