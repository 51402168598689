import {datadogRum} from '@datadog/browser-rum'
import env from '@beam-australia/react-env'

const applicationId = env('DD_APP_KEY')
const clientToken = env('DD_CT_KEY')
const commitSha = env('COMMIT_SHA')
const branchName = env('BRANCH_NAME')

if (applicationId && clientToken) {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service: 'erp-web',
    env: branchName,
    version: commitSha,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.inmemori\.com/, /https:\/\/.*\.inmemori-dev\.com/]
  })

  datadogRum.startSessionReplayRecording()
} else if (process.env.CRM_WEB_ENV !== 'local') {
  console.warn('Datadog RUM SDK is not initialized.', {applicationId, clientToken})
}

export default datadogRum
