import {Divider, Typography} from 'antd'
import React from 'react'
import CartArticle from './cart-article'
import {CartSubCategory, CartDomain, CartCategory} from './cart.t'

const {Text} = Typography

interface CartSubCategoryProps {
  cart: CartDomain
  category: CartCategory
  subCategory: CartSubCategory
  backColor: string
  fontColor: string
}

const CartSubCategory: React.FC<CartSubCategoryProps> = ({
  cart,
  category,
  subCategory,
  backColor,
  fontColor
}: CartSubCategoryProps) => {
  return (
    <div style={{overflow: 'hidden'}}>
      <div
        className='py-1 px-4'
        style={{
          background: `${backColor}Af`
        }}>
        <Text strong style={{color: fontColor}}>
          {subCategory.name}
        </Text>
      </div>
      <>
        {subCategory.articles.map((cartItem, index) => (
          <React.Fragment key={cartItem._id}>
            <CartArticle cart={cart} category={category} cartItem={cartItem} />
            {index < subCategory.articles.length - 1 && <Divider className='mx-3 my-0' />}
          </React.Fragment>
        ))}
      </>
    </div>
  )
}

export default CartSubCategory
