import FormComponent from '@components/forms/form'
import {FormObject, FormState} from '@components/forms/form.t'
import {withApp} from '@store/app'
import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react'
import {FormikProps} from 'formik'
import {FormPanelProps} from './form'
import {Row, Button, Typography, Col, Space, Divider, Drawer} from 'antd'
import {CloseOutlined} from '@ant-design/icons'
import {useInmemoriServices} from '@services'
import {
  Cart,
  CartItem,
  SearchProductArticles,
  SearchProductArticlesResult
} from '@shared/interfaces'

import {PanelsKeys} from '@libs/panels/panels.t'
import LookupComponent from '@components/lookup-component'
import {TFunction} from 'i18next'
import {useCallbackRef} from 'use-callback-ref'
import {CartItemSearchContext} from '@components/forms/fields/widgets/cart-widget/cart.t'

const {Title} = Typography
interface ResultViewProps {
  t: TFunction
  result: SearchProductArticlesResult
  onArticleClicked?: (articleId?: string) => void
}

export interface ProductFinderPanelProps extends FormPanelProps {
  cart: Cart
  cartItem?: CartItem
  searchContext?: CartItemSearchContext
}

type LookupArticlesQuery = {_id: {$in: string[]}}

const ProductFinderHeader: React.FC<PropsWithChildren<{title?: string}>> = ({title, children}) => (
  <div>
    <Row justify='space-between'>
      <Col>
        <Title level={4}>{title}</Title>
      </Col>
      <Col>
        <Row>
          <Space>
            {children && children}
            <Button
              icon={<CloseOutlined />}
              onClick={() => window.Panels.close(PanelsKeys.PRODUCT_FINDER)}
            />
          </Space>
        </Row>
      </Col>
    </Row>
    <Divider style={{marginTop: 5, marginBottom: 5}} />
  </div>
)

const ResultView: React.FC<ResultViewProps> = ({result, onArticleClicked, t}: ResultViewProps) => {
  const placeResults = result.placeArticles || []
  const sectorResults = result.sectorArticles || []
  const productArticles = result.productArticles || []
  const place = result.places && result.places[0]

  const [items, title] = useMemo(() => {
    if (place) {
      if (placeResults && placeResults.length)
        return [placeResults, t('panel.productFinder.placeArticles')]
      if (sectorResults && sectorResults.length)
        return [sectorResults, t('panel.productFinder.sectorArticles')]
      return [productArticles, t('panel.productFinder.productArticles')]
    } else return [productArticles, t('panel.productFinder.productArticles')]
  }, [])

  const q = useMemo<LookupArticlesQuery>(() => {
    return {
      _id: {$in: (items || []).map((v) => v._id)}
    }
  }, [items])

  return (
    <div style={{height: '100%'}}>
      <ProductFinderHeader title={title} />
      <LookupComponent
        domainName={'articles'}
        setSelectedLookupItem={(value) => {
          if (onArticleClicked) onArticleClicked(value?._id)
        }}
        autoSelect={false}
        lookupOptions={{q, context: {globalAccess: true}}}
      />
    </div>
  )
}

const ProductFinder: React.FC<ProductFinderPanelProps> = ({
  t,
  action,
  cartItem,
  cart,
  searchContext
}) => {
  const erpApi = useInmemoriServices().erpApiService

  const [isLoading, setIsLoading] = useState(false)
  const [formState, setFormState] = useState<FormState>({
    isDirty: false,
    isValid: false,
    isSubmitting: false
  })

  const formikRef = useCallbackRef<FormikProps<FormObject>>(null, (node) => {
    if (node) {
      if (
        node.dirty !== formState.isDirty ||
        node.isValid !== formState.isValid ||
        node.isSubmitting !== formState.isSubmitting
      )
        setFormState({isDirty: node.dirty, isValid: node.isValid, isSubmitting: node.isSubmitting})
    }
  })
  const [results, setResults] = useState<SearchProductArticlesResult | null>(null)

  useEffect(() => {
    if (searchContext?.service && !formikRef.current?.values.service) {
      formikRef.current?.setFieldValue('service', searchContext.service)
    }
    if (searchContext?.product) {
      formikRef.current?.setFieldValue('product', searchContext.product)
    }
  }, [formikRef?.current])

  return (
    <div>
      <div>
        <ProductFinderHeader title={t('panel.productFinder.productSearch') as string}>
          <Button
            type='primary'
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm()
              }
            }}>
            {t('components.lookup-component.form-filter.search')}
          </Button>
        </ProductFinderHeader>

        <FormComponent
          formId='addProduct'
          disabled={false}
          innerRef={formikRef}
          data={{
            project: cart.project as unknown as FormObject,
            searchContext: searchContext as unknown as FormObject
          }}
          onSubmit={(values) => {
            setIsLoading(true)
            const {product, options, service} = values as Pick<
              SearchProductArticles,
              'product' | 'options' | 'service'
            >

            erpApi
              .searchProductArticles({
                product,
                options,
                service,
                cart,
                action
              })
              .then(({data: {data}}) => {
                setResults(data)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        />

        <Drawer width={600} closable={false} open={!!results} onClose={() => setResults(null)}>
          {results && (
            <ResultView
              t={t}
              result={results}
              onArticleClicked={(articleId) => {
                if (articleId) {
                  if (cartItem) {
                    erpApi.replaceCartItemArticle(articleId, cartItem, false).then(() => {
                      window.Panels.close(PanelsKeys.PRODUCT_FINDER)
                      window.app.toast.success()
                      window.SM?.sub.refresh('cart')
                    })
                  } else {
                    erpApi.addToCart([articleId], cart?._id).then(() => {
                      window.Panels.close(PanelsKeys.PRODUCT_FINDER)
                      window.app.toast.success()
                      window.SM?.sub.refresh('cart')
                    })
                  }
                }
              }}
            />
          )}
        </Drawer>
      </div>
    </div>
  )
}

export default withApp(ProductFinder)
