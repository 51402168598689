import React, {useMemo} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import {Product} from '@shared/interfaces'
import {CatalogArticle} from '@store/catalog/context'
import {url} from '@libs/cloudinary'
import {Col, Row} from 'antd'

const MAX_NAME_LENGTH = 25

interface ArticleItemProps {
  article: CatalogArticle
  product?: Product
  setArticle?: React.Dispatch<CatalogArticle>
  belongsToCart: boolean
}

const ArticleItem: React.FC<ArticleItemProps> = ({
  article,
  product,
  setArticle,
  belongsToCart = false
}) => {
  const src = useMemo(() => {
    const pics = article?.pics || product?.pics || []
    const image = _.chain(pics).head().get('hash').value()
    return url(image)
  }, [article, product])

  const articleName = useMemo(() => {
    if (article.name && article.name.length > MAX_NAME_LENGTH) {
      return `${article.name.slice(0, MAX_NAME_LENGTH)} ...`
    }

    return article.name
  }, [article.name])

  return (
    <React.Fragment>
      <div
        className={`cursor-pointer d-flex flex-column align-items-start card-catalog card ${
          belongsToCart ? 'card-belongsto-cart' : ''
        }`}
        onClick={() => {
          setArticle?.({
            ...article,
            belongsToCart
          })
        }}>
        <div className='card-body card-body-catalog'>
          {belongsToCart && (
            <span className='belongs-to-card p-2 cursor-pointer'>
              <FontAwesomeIcon size={'2x'} icon={'check'} fixedWidth color='green' />
            </span>
          )}
          <img className='catalog-card-img img-fluid' src={src} style={{height: 'inherit'}} />
        </div>
        <Row className='card-title gotham-font row' style={{margin: '0px'}}>
          <Col span={16}>
            <p className='mt-2 mx-auto'>{articleName}</p>
          </Col>
          <Col span={8}>
            <p className='article-text-ar mt-2 mx-auto'>
              {article.price?.ttc}
              {' €'}
            </p>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ArticleItem
