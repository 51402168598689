import {useMemo} from 'react'
import {Position} from 'react-flow-renderer/nocss'
import dagre from 'dagre'
import {Edge, NodeConfigs, Node} from '../nodes/node-config'
import {Size as DropSize} from '../nodes/flow/drop-node'
import {Size as StartSize} from '../nodes/flow/start-node'

interface PositionMap {
  [id: string]: Position
  T: Position.Top
  L: Position.Left
  R: Position.Right
  B: Position.Bottom
}

const PositionMap: PositionMap = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom
}

const sizes = {
  article: NodeConfigs.article.size,
  document: NodeConfigs.document.size,
  documentRaw: NodeConfigs.documentRaw.size,
  product: NodeConfigs.product.size,
  productOptions: NodeConfigs.productOptions.size,
  drop: DropSize,
  multiRuleSplit: NodeConfigs.multiRuleSplit.size,
  ruleSplit: NodeConfigs.ruleSplit.size,
  split: NodeConfigs.split.size,
  service: NodeConfigs.service.size,
  start: StartSize,
  domainTask: NodeConfigs.domainTask.size
}

const layoutGraph = (nodes: Node[], edges: Edge[], {direction = 'TB'}) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 100,
    edgesep: 10,
    ranksep: 60,
    marginx: 20,
    marginy: 20
  })

  nodes.forEach((el) => {
    dagreGraph.setNode(el.id, {...sizes[el.type]})
  })

  edges.forEach((el) => {
    dagreGraph.setEdge(el.source, el.target)
  })

  dagre.layout(dagreGraph)

  const layoutNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = PositionMap[direction[0]]
    node.sourcePosition = PositionMap[direction[1]]

    node.position = {
      x: nodeWithPosition.x - sizes[node.type].width / 2,
      y: nodeWithPosition.y - sizes[node.type].height / 2
    }

    return node
  })

  return layoutNodes
}

const useLayout = (nodes: Node[], edges: Edge[], options?: {direction: string}) => {
  return useMemo(
    () => layoutGraph(nodes, edges, options || {direction: 'TB'}),
    [nodes, edges, options]
  )
}

export default useLayout
