import * as Yup from 'yup'
import {FieldWrapper} from './fields/fields.t'

export enum ValidationActionKind {
  ADD_FIELD = 'addField',
  REMOVE_FIELD = 'removeField',
  MERGE_SCHEMA = 'mergeSchema',
  REMOVE_KEYS = 'removeKeys',
  RESET = 'reset'
}

export interface ValidationAction {
  kind: ValidationActionKind
  field?: FieldWrapper
  schema?: Yup.ObjectSchema<Yup.AnyObject>
  keys?: string[]
}

export interface ValidationState {
  schema: Yup.ObjectSchema<Yup.AnyObject>
}

export type FormObjectValue = string | number | boolean | null | undefined

export interface FormObject {
  _id?: string
  [key: string]: FormObjectValue | FormObject | FormObject[] | FormObjectValue[]
}

export interface FormState {
  isDirty: boolean
  isValid: boolean
  isSubmitting: boolean
}
