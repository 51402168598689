import React from 'react'
import {TFunction} from 'next-i18next'
import SearchBar from '@components/lookup-component/search-bar'

interface SearchbarProps {
  value?: string
  onChange: (value: string) => void
  isLoading?: boolean
  t: TFunction
}

const Searchbar: React.FC<SearchbarProps> = ({t, onChange, isLoading}) => {
  return (
    <div className='my-3 catalog-searchbar-container'>
      <label className='catalog-searchbar-label'>{t('catalog.sidebar.searchLabel')}</label>
      <SearchBar
        placeholder={t('catalog.sidebar.searchPlaceholder') as string}
        onSearch={onChange}
        loading={isLoading}
        className='catalog-searchbar-input'
      />
    </div>
  )
}

export default Searchbar
