import {useGetByIdQuery} from '@queries'
import {useAddToCartMutation} from '@queries/carts'
import {Cart, Product} from '@shared/interfaces'
import {useApp} from '@store/app'
import {CatalogArticle, useCataLogContext} from '@store/catalog/context'
import _ from 'lodash'
import React, {useCallback, useMemo} from 'react'
import Article from './article'
import ArticlesList from './articles-list'

const Content: React.FC = () => {
  const {
    data,
    setArticle,
    filters,
    products,
    sidebarHidden,
    isLoading,
    article,
    extended,
    cart: cartProp,
    hasProject
  } = useCataLogContext()

  const product = useMemo<Product | undefined>(
    () => products?.find(({_id}) => _id === filters?.product),
    [products, filters?.product]
  )

  const articles = useMemo(
    () => (extended ? data?.articles : data?.articles?.filter(({tags}) => !_.isEmpty(tags))),
    [extended, data?.articles]
  )

  const numberOfColumns = useMemo<2 | 3>(() => (sidebarHidden ? 2 : 3), [sidebarHidden])

  const articleIndex = useMemo<number | undefined>(() => {
    return article && (articles || []).findIndex(({_id}) => _id === article?._id)
  }, [article, articles])

  const onNavigate = useMemo<{
    next: () => CatalogArticle | undefined
    previous: () => CatalogArticle | undefined
  }>(
    () => ({
      next(): CatalogArticle | undefined {
        return _.get(articles, (articleIndex || 0) + 1, undefined)
      },
      previous(): CatalogArticle | undefined {
        return _.get(articles, (articleIndex || 0) - 1, undefined)
      }
    }),
    [articleIndex, articles]
  )

  const {
    data: cart,
    isLoading: cartLoading,
    isError
  } = useGetByIdQuery<Cart>('carts', cartProp?._id || '', {
    query: {enabled: Boolean(cartProp?._id)}
  })

  const belongsToCart = useCallback(
    (articleId: string) => Boolean(cart?.cartItems.map((v) => v.article).includes(articleId)),
    [cart]
  )

  const addToCartMutation = useAddToCartMutation()
  const {toast, t} = useApp()

  const onArticleAdd = (articleId: string) => {
    if (cart && filters?.product) {
      toast.promise(
        addToCartMutation.mutateAsync({
          articleId,
          cart,
          allArticleIds: data?.articles.map(({_id}) => _id) || [],
          productId: filters?.product
        }),
        {
          pending: t('catalog.article.addToCartToast.pending'),
          success: t('catalog.article.addToCartToast.success', {article}),
          error: t('catalog.article.addToCartToast.error')
        }
      )
    }
  }

  return (
    <div className='catalog-content flex-grow-1 position-relative overflow-scroll'>
      <div className='h-100'>
        {article ? (
          <Article
            article={article}
            products={_.groupBy(products, '_id')}
            previous={onNavigate.previous()}
            next={onNavigate.next()}
            setArticle={setArticle}
            onArticleAdd={onArticleAdd}
            belongsToCart={belongsToCart(article._id)}
            hasProject={hasProject}
            loading={cartLoading}
            isError={isError}
          />
        ) : (
          <ArticlesList
            numberOfColumns={numberOfColumns}
            articles={articles || []}
            setArticle={setArticle}
            product={product}
            isLoading={isLoading}
            belongsToCart={belongsToCart}
          />
        )}
      </div>
    </div>
  )
}

export default Content
